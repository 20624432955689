import http from "../utils/http"
const baseUrl = '/app/study'

/**
 * 根据名称和分类ID查询课程列表
 * @params
 * method:get
 * path:'/app/study/courses'
 */

export const getCourses = params => http.get(`${baseUrl}/courses`, {params})

/**
 * 根据课程ID查询信息
 * @params
 * method:get
 * path:'/app/study/courses/{id}'
 */

export const getCourseDetail = id => http.get(`${baseUrl}/courses/${id}`)

/**
 * 查询收藏
 * @params
 * method:get
 * path:'/app/study/collect'
 */

export const getCollect = params => http.get(`${baseUrl}/collect`, {params})

/**
 * 添加收藏
 * @params
 * method:post
 * path:'/app/study/collect/{courseId}'
 */

export const addCollect = courseId => http.post(`${baseUrl}/collect/${courseId}`)

/**
 * 批量删除收藏
 * @params
 * method:delete
 * path:'/app/study/collect/{courseIds}'
 */

export const delCollect = courseIds => http.delete(`${baseUrl}/collect/${courseIds}`)

/**
 * 查询课程评价
 * @params
 * method:get
 * path:'/app/study/appraise'
 */

export const appraises = params => http.get(`${baseUrl}/appraise`, {params})

/**
 * 新增评价
 * @params
 * method:post
 * path:'/app/study/appraise'
 */

export const addAppraises = params => http.post(`${baseUrl}/appraise`, params)

/**
 * 获取我购买的课程
 * @param
 * method:get
 * path:'/app/study/order/memberOrder'
*/

export const getMenberOrder = params => http.get(`${baseUrl}/order/memberOrder`, {params})

/**
 * 新增订单
 * @params
 * method:post
 * path:'/app/study/order/{courseId}'
 */

export const addOrder = params => http.post(`${baseUrl}/order/${params.courseId}`, params)

/**
 * 查询订单支付状态
 * @params
 * method:get
 * path:'/app/study/order/getOrderStatus'
 */

export const orderStatus = params => http.get(`${baseUrl}/order/getOrderStatus`, { params } )

/**
 *获取视频播放地址
 *@params
 *method:get
 *path: '/app/study/courses/chapters/{id}/videos'
 */

export const videoAddress = id => http.get(`${baseUrl}/courses/chapters/${id}/videos`)

/**
 *聊天消息记录
 * @params {
 * courseId    课程id
 * chapterId    章节id
 * classId    班级id
 * }
 * method:get
 * path:'/app/study/chat/message/history'
 */
 
 export const chatHistory = (courseId, chapterId, classId) => http.get(`${baseUrl}/chat/message/history/${courseId}/${chapterId}/${classId}`)
 
/**
 *随堂测试
 *@params
 *method:get
 *path: '/app/study/courses/chapters/{id}/test'
 */

export const getTest = id => http.get(`${baseUrl}/courses/chapters/${id}/test`)

/**
 *提交随堂测试
 *@params
 *method:post
 *path: '/app/study/courses/chapters/{id}/test'
 */

export const submitTest = (id, answer) => http.post(`${baseUrl}/courses/chapters/${id}/test`, answer)

/**
 *获取章节ppt
 *@params
 *method:get
 *path: '/app/study/courses/chapters/{id}/ppts'
 */

export const getPPT = id => http.get(`${baseUrl}/courses/chapters/${id}/ppts`)

/**
 *资料下载
 *@params
 *method:get
 *path: '/app/study/courses/chapters/{id}/files'
 */

export const getEnclosure = id => http.get(`${baseUrl}/courses/chapters/${id}/files`)


/**
 * 获取章节文档
 * @param {} params 
 */
export const getDocs = params => http.get(`${baseUrl}/chapter/docs`, { params })
